/**
 * ================================================
 * Warehouse shipping dashboard API
 * This factory contains all the methods for interacting with the warehouse shipping dashboard feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import qs from 'qs'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Route
 * ================================================
 */
const API_ROUTE = '/api/v2/warehouse/shipments'

/**
 * get Dashboard
 */
const useGetDashboard = (payload: {
  country: ComputedRef<string>
  options?: UseQueryOptions<T.warehouseShippingDashboardGetDashboardResponse>
}) =>
  useQuery({
    queryKey: ['WarehouseShippingDashboard', payload.country],
    queryFn: (context) =>
      useWretch('FsyncAPI')
        .url(`${API_ROUTE}/dashboard`)
        .query(
          qs.stringify(
            { country: context.queryKey[1] },
            { encode: false, skipNulls: true }
          )
        )
        .get()
        .json<T.warehouseShippingDashboardGetDashboardResponse>()
        .then(
          ({
            orders: orderData,
            orders_processing: ordersProcessing,
            orders_processing_total: total
          }) => {
            const data = Object.values(ordersProcessing)

            return {
              orders: orderData,
              orders_processing: data,
              orders_processing_total: total
            }
          }
        )
        .catch((err) => err),
    ...payload.options
  })

/**
 * deleted shipment
 */
const useDeleteShipment = (payload?: {
  options?: UseMutationOptions<
    T.warehouseShippingDashboardDeleteShipmentResponse,
    unknown,
    T.warehouseShippingDashboardDeleteShipmentRequest
  >
}) =>
  useMutation({
    mutationFn: (payload) =>
      useWretch('FsyncAPI')
        .url(`${API_ROUTE}/${payload.id}`)
        .delete()
        .json<T.warehouseShippingDashboardDeleteShipmentResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...payload?.options,
    onSuccess: () =>
      notifier({
        message: 'Shipment deleted successfully',
        type: 'success'
      }),
    onError: (error) =>
      notifier({
        // @ts-expect-error error is not null
        message: error?.message,
        type: 'success'
      })
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetDashboard
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useDeleteShipment
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const warehouseShippingDashboardApiFactory = {
  queries,
  mutations
}
