/**
 * ================================================
 * Warehouse API factory
 * This factory contains all the methods for interacting with the warehouse feature.
 * ================================================
 */

/**
 * ================================================
 * NOTES: 👍
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import { exportFile } from 'quasar'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'
import type * as CT from './container-types'
import type * as WT from './warehouse-types'
import useAuthStore from '~/features/auth/store'

/**
 * ================================================
 * API Routes
 * ================================================
 */
const API_PREFIX = '/api/v2/warehouse'
// const API_PREFIX_V1 = '/api/v1/location'
// const NEW_API_ROUTE = '/document/DocumentGeneratorV2/generateFile'

/**
 * Get Werehouse Locations - call
 *
 * Retrieves the warehouse locations for a given region.
 * @param region - The region for which to retrieve warehouse locations.
 * @returns A promise that resolves to the response containing the warehouse locations.
 */
export const getWarehouseLocations = async (
  context: QueryFunctionContext
): Promise<T.warehouseGetWerehouseLocationsResponse> =>
  await useWretch('FsyncAPI')
    .get(`${API_PREFIX}/shipments/warehouse-locations/${context.queryKey[1]}`)
    .json<T.warehouseGetWerehouseLocationsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get Werehouse Locations - Query
 *
 * Retrieves warehouse locations based on the specified region.
 * @param payload - The payload object containing the region and optional query options.
 * @returns The result of the useQuery hook.
 */
const useGetWarehouseLocations = (payload: {
  region: ComputedRef<string>
  options?: UseQueryOptions<T.warehouseGetWerehouseLocationsResponse>
}) =>
  useQuery({
    queryKey: ['WarehouseLocations', payload.region],
    queryFn: getWarehouseLocations,
    ...payload.options
  })

/**
 * Get shipment delivery note - call
 */
export const getShipmentDeliveryNote = async (
  payload: T.warehouseGetShipmentDeliveryNoteRequest
): Promise<T.warehouseGetShipmentDeliveryNoteResponse> => {
  const country = computed(() => useAuthStore().selectedCountry.name)

  const documentId =
    payload.documentId +
    `${payload.warehouseLocation ? '~' + payload.warehouseLocation.toString() : ''}`

  return await useWretch('FrAPI')
    .url(`/document/DocumentGeneratorV2/generateFile/${country.value}`)
    .query({
      Original: true
    })
    .json({
      documentId: documentId,
      documentType: 'DeliveryNote',
      warehouseLocation: payload.warehouseLocation,
      language: payload.language || 'EN'
    })
    .post()
    .blob()
    .then(async (res) => {
      // const file = (await toBase64(res)) as string
      if (payload.force_download) {
        try {
          // Download PDF
          const status = exportFile(documentId + '.pdf', res)

          if (status === true) {
            console.log('Sccuess: browser allowed it ')
          } else {
            console.log('Error: browser denied it ')
          }
        } catch (error) {
          console.log(error)
        }
      }

      return {
        contents: res,
        file_name: documentId,
        file_type: 'application/pdf'
      }
    })
    .catch((err) => err)
}

/**
 * Get shipment delivery note - Mutation
 */
const useGetShipmentDeliveryNote = (
  options?: UseMutationOptions<
    T.warehouseGetShipmentDeliveryNoteResponse,
    Error,
    T.warehouseGetShipmentDeliveryNoteRequest
  >
) =>
  useMutation({
    mutationFn: getShipmentDeliveryNote,
    ...options
  })

/**
 * get shipment label - call
 */
export const getShipmentLabel = async (
  payload: T.warehouseGetShipmentCarrierLabelsRequest
): Promise<T.warehouseGetShipmentCarrierLabelsResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/shipments/labels/download`)
    .json({
      force_download: payload.force_download,
      label_id: payload.label_id,
      shipment_id: payload.shipment_id
    })
    .post()
    .json<T.warehouseGetShipmentCarrierLabelsResponse>()
    .then((res) => {
      const file = blobHelper(res.contents, res.file_name, res.file_type)

      if (payload.force_download) {
        try {
          // Download PDF
          const status = exportFile(res.file_name, file)

          if (status === true) {
            console.log('Success: browser allowed it ')
          } else {
            console.log('Error: browser denied it ')
          }
        } catch (error) {
          console.log(error)
        }
      }

      return {
        contents: file,
        file_name: res.file_name,
        file_type: res.file_type
      }
    })
    .catch((err) => err)

/**
 * get shipment label - Mutation
 */
const useGetShipmentLabel = (
  options?: UseMutationOptions<
    T.warehouseGetShipmentCarrierLabelsResponse,
    Error,
    T.warehouseGetShipmentCarrierLabelsRequest
  >
) =>
  useMutation({
    mutationFn: getShipmentLabel,
    ...options
  })

/**
 * Document search - call
 */
const documentSearch = async (
  payload: T.warehouseDocumentSearchRequest
): Promise<T.warehouseDocumentSearchResponse> =>
  await useWretch('FrAPI')
    .url('/order/api/Sales/RSA/search')
    .query({
      region: payload.region,
      SearchInHeader: payload.SearchInHeader
    })
    .get()
    .json<T.warehouseDocumentSearchResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Document search - Query
 */
const useDocumentSearch = (payload: {
  region: ComputedRef<T.warehouseDocumentSearchRequest['region']>
  SearchInHeader: ComputedRef<string>
  options?: UseQueryOptions<T.warehouseDocumentSearchResponse>
}) =>
  useQuery({
    queryKey: ['DocumentSearch', payload.region, payload.SearchInHeader],
    queryFn: () =>
      documentSearch({
        region: payload.region.value,
        SearchInHeader: payload.SearchInHeader.value
      }),
    ...payload.options
  })

/**
 * Document search - Query
 */
const getProductInformation = async (
  stockCode: string,
  retailOnly: boolean
): Promise<T.getProductInformationResponse | null> =>
  await useWretch('frSysproAPI')
    .url(`/product/product/detail/${stockCode}?RetailOnly=${retailOnly}`)
    .get()
    .json<T.getProductInformationResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getProductInformation:', err)
      return null
    })

async function getAllContainers(): Promise<CT.GetContainersResponse | null> {
  return await useWretch('FsyncAPI')
    .get('/api/v2/warehouse/logistics/containers')
    .json<CT.GetContainersResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getContainers:', err)
      return null
    })
}

async function getContainersFilter(
  getBy: CT.ContainerFilterBy,
  value: string | number
): Promise<CT.GetContainersResponse | null> {
  return await useWretch('FsyncAPI')
    .get(`/api/v2/warehouse/logistics/containers/${getBy}/${value}`)
    .json<CT.GetContainersResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getContainersFilter:', err)
      return null
    })
}

async function getContainersByTrackingNumber(
  trackingNumber: string
): Promise<CT.GetContainersByTrackingNumberResponse | null> {
  return await useWretch('FsyncAPI')
    .get(`/api/v2/warehouse/logistics/containers/trackingNo/${trackingNumber}`)
    .json<CT.GetContainersByTrackingNumberResponse>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getContainersByTrackingNumber:', err)
      return null
    })
}

/**
 * Get bins for warehouse/product
 */
async function getWarehouseBins(warehouseId: string): Promise<string[] | null> {
  return await useWretch('frSysproAPI')
    .get(`/warehouse/api/warehouse/${warehouseId}/Bins`)
    .json<string[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getWarehouseBins:', err)
      return null
    })
}

/**
 * Get stock item locations
 */
async function getStockItemLocations(
  stockCode: string
): Promise<WT.StockItemLocationResponse[] | null> {
  return await useWretch('frSysproAPI')
    .get(`/warehouse/api/Locate/StockCode/${stockCode}`)
    .json<WT.StockItemLocationResponse[]>()
    .then((res) => res)
    .catch((err) => {
      console.error('Error in getStockLocations:', err)
      return null
    })
}

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetWarehouseLocations,
  useDocumentSearch
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useGetShipmentDeliveryNote,
  useGetShipmentLabel
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const warehouseApiFactory = {
  getProductInformation,
  getAllContainers,
  getContainersFilter,
  getContainersByTrackingNumber,
  getWarehouseBins,
  getStockItemLocations,
  queries,
  mutations
}
